import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    BusinessClient,
    BusinessEntityType,
    Client,
    ContactMethodType,
    updateBusiness,
    UpdateBusinessFields,
} from '../../../apis/clients';
import { LoadingButton } from '../../../components/LoadingButton';

const mobileRegex = /(^(\+?64|0)2[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$)/;

const schema = yup.object({
    businessNumber: yup.string().required('Business number required'),
    entityType: yup.mixed<BusinessEntityType>().oneOf(Object.values(BusinessEntityType)).required(),
    businessName: yup.string().required('Business name required'),
    authorizedIndividual: yup.object({
        email: yup.string().email('Valid email required').optional(),
        firstName: yup.string().required('Authorized first name required'),
        lastName: yup.string().required('Authorized last name required'),
        mobileNumber: yup
            .string()
            .required('Authorized mobile number required')
            .matches(mobileRegex, 'Valid mobile number required'),
    }),
    preferredContactMethodType: yup
        .mixed<ContactMethodType>()
        .oneOf(Object.values(ContactMethodType))
        .required('Preferred contact method required'),
});

interface UpdateBusinessClientProps {
    callback: (client: Client) => void;
    client: BusinessClient;
    closeDialog?: () => void;
}

const UpdateBusinessClient = ({ callback, client, closeDialog }: UpdateBusinessClientProps) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<UpdateBusinessFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<UpdateBusinessFields> = (data) => {
        setSubmitting(true);

        updateBusiness(data, client?.uuid)
            .then((clientResponse) => {
                callback(clientResponse);
            })
            .catch(({ response }) => {
                setErrorMsg(response.data.message);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
            }}
        >
            <DialogTitle>Edit business</DialogTitle>

            <DialogContent sx={{ width: 480 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='businessName'>Legal business name</FormLabel>
                            <Controller
                                name='businessName'
                                control={control}
                                defaultValue={client?.businessName}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size='medium'
                                        id='businessName'
                                        autoComplete='no'
                                        error={!!errors?.businessName}
                                        helperText={errors.businessName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='entityType-label'>Business type</FormLabel>
                            <Controller
                                name='entityType'
                                control={control}
                                defaultValue={client?.entityType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id='entityType'
                                        fullWidth
                                        error={!!errors?.entityType}
                                        size='medium'
                                    >
                                        <MenuItem value={BusinessEntityType.REGISTERED_COMPANY}>
                                            Registered company
                                        </MenuItem>
                                        <MenuItem value={BusinessEntityType.SOLE_TRADER}>Sole trader</MenuItem>
                                        <MenuItem value={BusinessEntityType.LIMITED_LIABILITY}>
                                            Limited liability
                                        </MenuItem>
                                    </Select>
                                )}
                            />
                            <FormHelperText>{errors.entityType?.message}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='businessNumber'>Business number</FormLabel>
                            <Controller
                                name='businessNumber'
                                control={control}
                                defaultValue={client?.businessNumber}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size='medium'
                                        id='businessNumber'
                                        autoComplete='no'
                                        error={!!errors?.businessNumber}
                                        helperText={errors.businessNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='preferredContactMethodType'>Preferred contact method</FormLabel>
                            <Controller
                                name='preferredContactMethodType'
                                control={control}
                                defaultValue={client?.preferredContactMethodType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id='preferredContactMethodType'
                                        fullWidth
                                        error={!!errors?.preferredContactMethodType}
                                        size='medium'
                                    >
                                        <MenuItem value={ContactMethodType.EMAIL}>Email</MenuItem>
                                        <MenuItem value={ContactMethodType.POST}>Post</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'h6'}>Authorized Individual</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.firstName'>First name</FormLabel>
                            <Controller
                                name='authorizedIndividual.firstName'
                                control={control}
                                defaultValue={client?.authorizedIndividual.personalDetails.givenName}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='authorizedIndividual.firstName'
                                        size='medium'
                                        autoComplete='no'
                                        error={!!errors?.authorizedIndividual?.firstName}
                                        helperText={errors.authorizedIndividual?.firstName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.lastName'>Last name</FormLabel>
                            <Controller
                                name='authorizedIndividual.lastName'
                                control={control}
                                defaultValue={client?.authorizedIndividual.personalDetails.surname}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='authorizedIndividual.lastName'
                                        size='medium'
                                        autoComplete='no'
                                        error={!!errors?.authorizedIndividual?.lastName}
                                        helperText={errors.authorizedIndividual?.lastName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='authorizedIndividual.email'>Email</FormLabel>
                            <Controller
                                name='authorizedIndividual.email'
                                control={control}
                                defaultValue={client?.authorizedIndividual.contactDetails.email?.address}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='authorizedIndividual.email'
                                        size='medium'
                                        fullWidth
                                        autoComplete='no'
                                        error={!!errors?.authorizedIndividual?.email}
                                        helperText={errors.authorizedIndividual?.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.mobileNumber'>Mobile number</FormLabel>
                            <Controller
                                name='authorizedIndividual.mobileNumber'
                                control={control}
                                defaultValue={client?.authorizedIndividual.contactDetails.preferredPhoneNumber.number}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        id='authorizedIndividual.mobileNumber'
                                        size='medium'
                                        fullWidth
                                        type='tel'
                                        autoComplete='no'
                                        error={!!errors?.authorizedIndividual?.mobileNumber}
                                        helperText={errors.authorizedIndividual?.mobileNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    {errorMsg && (
                        <Grid item sm={12}>
                            <Alert severity='error'>{errorMsg}</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button size='large' onClick={closeDialog}>
                    Close
                </Button>
                <LoadingButton
                    data-testid='saveClient'
                    type='submit'
                    variant='contained'
                    size='large'
                    loading={submitting}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </form>
    );
};

export default UpdateBusinessClient;
