import axios from 'axios';
import { InvoiceDetailsResponse, PaymentFrequency } from './invoice';
import { cloneDeep } from 'lodash';

// request types
type QuoteRequest = {
    uuid?: string;
    reason: QuoteReason;
    insuredIdentifier: string;
    status: QuoteStatus;
};

export enum QuoteReason {
    ENDORSEMENT = 'ENDORSEMENT',
}

export enum QuoteStatus {
    DRAFT = 'DRAFT',
    QUOTED = 'QUOTED',
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
}

export enum CoverChargeType {
    PREMIUM = 'PREMIUM',
    GST = 'GST',
}

type CoverRequest = {
    coverageAmount: number;
    productCoverIdentifier: string;
};

export enum CoverQuoteOutcome {
    UNAVAILABLE = 'UNAVAILABLE',
    SUCCESS = 'SUCCESS',
}

export type BeneficiaryRequest = {
    uuid?: string;
    name: string;
    distribution: number;
    dateOfBirth?: string;
    relationshipToPolicyHolder?: string;
    emailAddress?: string;
    address?: string;
    contactNumber?: string;
};

export type UnderwritingDetailsRequest = {
    uuid?: string;
    productQuestionnaireIdentifier: string;
    answers: string;
};

export type ItemRequest = {
    uuid?: string;
    requestedCoverages: CoverRequest[];
    beneficiaries: BeneficiaryRequest[];
    underwritingDetails: UnderwritingDetailsRequest;
    type: ItemType;
    name: string;
};

export enum ItemType {
    PERSON = 'PERSON',
}

export type CreateEnquiryRequestItem = {
    type: string;
    name: string;
    label: string;
    value: string;
    values?: { label: string; value: string; selected: boolean }[];
};

export type PolicyVersionRequest = {
    uuid?: string;
    quote: QuoteRequest;
    items: ItemRequest[];
    reason: PolicyVersionReason;
    effectiveDate: string;
    anniversaryDate: string;
    endDate: string;
    productIdentifier: string;
};

export enum PolicyVersionReason {
    CREATION = 'CREATION',
    RENEWAL = 'RENEWAL',
    ENDORSEMENT = 'ENDORSEMENT',
    QUOTE = 'QUOTE',
}

// preview response types
export type PolicyVersionPreview = {
    totalAnnualPremium: number;
    proRataPremiumDifference: number;
    versionPreview: PolicyVersion;
};

// quote response types
export type Quote = Omit<QuoteRequest, 'uuid'> & {
    uuid: string;
};

export type CoverCharge = {
    uuid: string;
    type: CoverChargeType;
    amount: number;
};

export type CoverOption = {
    uuid: string;
    coverCharges: CoverCharge[];
    instalmentPreviews: InstalmentPreviewWrapper[];
};

export type RequestedCoverage = {
    productCoverCode: string;
    productCoverName: string;
    productCoverIdentifier: string;
    coverageAmount: number;
    fixedCoverage: boolean;
};

export type Cover = RequestedCoverage & {
    uuid: string;
    selectedCoverOption: CoverOption;
    coverOptions: CoverOption[];
    outcome: CoverQuoteOutcome;
};

export type CoverSet = {
    uuid: string;
    covers: Cover[];
    coverStartDate: string;
    instalmentPreviews: InstalmentPreviewWrapper[];
};

export type Beneficiary = Omit<BeneficiaryRequest, 'omit'> & {
    uuid: string;
};

export type UnderwritingDetails = Omit<UnderwritingDetailsRequest, 'uuid'> & {
    uuid: string;
};

export type Item = {
    uuid: string;
    coverSets: CoverSet[];
    requestedCoverages: RequestedCoverage[];
    beneficiaries: Beneficiary[];
    underwritingDetails: UnderwritingDetails;
    type: ItemType;
    name: string;
};

export type PolicyVersion = {
    uuid: string;
    quote: Quote;
    items: Item[];
    version: number;
    reason: PolicyVersionReason;
    status: PolicyVersionStatus;
    effectiveDate: string;
    anniversaryDate: string;
    endDate: string;
    productName: string;
    productCode: string;
    productIdentifier: string;
    activeDate?: string;
    createdDate: string;
    lastModifiedDate: string;
    annualPremiums: number;
    renewFromPolicyVersionIdentifier?: string;
    instalmentPreviews: InstalmentPreviewWrapper[];
};

export type InstalmentPreviewWrapper = {
    instalmentPreview: InstalmentPreview;
};

export type InstalmentPreview = {
    paymentFrequency: PaymentFrequency;
    instalmentAmount: number;
    gstPerInstalment: number;
    annualPremiums: number;
    firstPaymentDate: string;
    numberOfAnnualPayments: number;
};

export enum PolicyVersionStatus {
    PENDING_RENEWAL = 'PENDING_RENEWAL',
    READY_FOR_RENEWAL = 'READY_FOR_RENEWAL',
    RENEWAL_PROCESSING = 'RENEWAL_PROCESSING',
    ACTIVE = 'ACTIVE',
    ACTIVE_PENDING_ACCEPTANCE = 'ACTIVE_PENDING_ACCEPTANCE',
    NOT_ACTIVE = 'NOT_ACTIVE',
}

export const previewQuote = async (
    invoiceIdentifier: string,
    policyIdentifier: string,
    request: PolicyVersionRequest
): Promise<PolicyVersionPreview> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/policies/${policyIdentifier}/policy-versions/_query`,
            trimPayload(request)
        )
        .then(({ data }) => data);
};

export const putQuote = async (
    invoiceIdentifier: string,
    policyIdentifier: string,
    request: PolicyVersionRequest
): Promise<InvoiceDetailsResponse> => {
    if (request.uuid) {
        return await axios
            .put(
                `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/policies/${policyIdentifier}/policy-versions/${request.uuid}`,
                trimPayload(request)
            )
            .then(({ data }) => data);
    } else {
        return await axios
            .post(
                `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/policies/${policyIdentifier}/policy-versions`,
                trimPayload(request)
            )
            .then(({ data }) => data);
    }
};

type SaturatedItem = ItemRequest & {
    covers?: unknown[];
    coverSets?: unknown[];
    questionnaire?: Record<string, unknown>;
};

const trimPayload = (request: PolicyVersionRequest): PolicyVersionRequest => {
    const clone = cloneDeep(request);
    clone.items.forEach((item: SaturatedItem) => {
        item.covers = undefined;
        item.questionnaire = undefined;
        item.coverSets = undefined;
    });
    return clone;
};
