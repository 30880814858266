import { Box, Chip, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { capitalize } from 'lodash';
import moment from 'moment';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../../util/dateUtils';
import { PolicyVersionPreview } from '../../../apis/quotes';
import { Invoice, PaymentFrequency } from '../../../apis/invoice';

type Props = {
    invoice: Invoice;
    policyVersionPreview: PolicyVersionPreview;
};

export default function NewInstalmentDetails({ invoice, policyVersionPreview }: Readonly<Props>) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box mb={1}>
                <Typography variant='h2' pb={0}>
                    Next instalment amount
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography variant='caption'>
                    Any instalments before{' '}
                    {moment(policyVersionPreview.versionPreview.effectiveDate, DATE_SERVER_FORMAT).format(
                        DATE_FRIENDLY
                    )}{' '}
                    will continue as normal. Once the endorsement is confirmed, the updated payment schedule will be
                    available in the payments tab. The client can change the payment term from their portal.
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'grid',
                    gap: 2,
                    gridTemplateColumns: { xs: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' },
                    mb: 1,
                }}
            >
                {policyVersionPreview.versionPreview.instalmentPreviews
                    .map((preview) => preview.instalmentPreview)
                    .filter((preview) => preview.paymentFrequency !== PaymentFrequency.IN_FULL)
                    .map((preview) => (
                        <Box
                            key={preview.paymentFrequency}
                            sx={{
                                border: grey[200],
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderRadius: theme.shape.borderRadius + 'px',
                                display: 'flex',
                                flexDirection: 'column',
                                p: 2,
                                justifyContent: 'center',
                            }}
                        >
                            {preview.paymentFrequency === invoice.term.paymentFrequency ? (
                                <>
                                    <Typography variant='h6'>
                                        {currencyFormatter.format(preview.instalmentAmount)}*
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        <Typography variant='h6'>{capitalize(preview.paymentFrequency)}</Typography>
                                        <Chip size='small' label='Current term' variant='outlined' color='info' />
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography variant='caption'>
                                        {currencyFormatter.format(preview.instalmentAmount)}*
                                    </Typography>
                                    <Typography variant='caption'>{capitalize(preview.paymentFrequency)}</Typography>
                                </>
                            )}
                        </Box>
                    ))}
            </Box>
            <Box>
                <Typography variant='subtitle1'>*Excludes transaction fees and potential renewal increases.</Typography>
            </Box>
        </Box>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
