import { Add } from '@mui/icons-material';
import {
    Avatar,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { chain, isNil } from 'lodash';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Control, UseFormClearErrors, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { AddressFields as AddressFieldList } from '../apis/clients';
import { Address, AddressType } from '../types/Types';

type AddressFieldsProps<T extends AddressFieldList> = {
    address?: Address;
    control: Control<T>;
    errors: FieldErrors;
    setValue: UseFormSetValue<T>;
    clearErrors: UseFormClearErrors<T>;
    inputSize?: 'small' | 'medium';
};

type MatchedAddresses = {
    matched: number;
    addresses: AddressMatch[];
};

type AddressMatch = {
    id: number;
    a: string;
};

const addyKey = process.env.REACT_APP_ADDY_KEY;

export const buildFullAddress = (address?: Address): string | undefined => {
    if (isNil(address)) {
        return undefined;
    }

    const { addressLine1, addressLine2, suburb, city, postCode } = address;

    const addressWithoutPostCode = chain([addressLine1, addressLine2, suburb, city])
        .filter((part) => !isNil(part) && part !== '')
        .join(', ')
        .value();

    return `${addressWithoutPostCode}${postCode ? ' ' + postCode : ''}`;
};

const AddressFields = <T extends AddressFieldList>(props: AddressFieldsProps<T>) => {
    const typeCastProps = props as unknown as AddressFieldsProps<AddressFieldList>;
    const { control, errors, setValue, address, clearErrors, inputSize } = typeCastProps;
    const [matchedAddresses, setMatchedAddresses] = useState({} as MatchedAddresses);

    const searchAddresses = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // clear current value when starting a new search
        setValue('addressLine1', '');
        setValue('addressLine2', '');
        setValue('suburb', '');
        setValue('cityTown', '');
        setValue('postcode', '');

        fetchAddresses(e.currentTarget.value);
    };

    const fetchAddresses = async (query: string) => {
        const fetchAddressesRequestPromise = async (): Promise<MatchedAddresses> => {
            const config = {
                headers: {
                    'addy-api-key': addyKey,
                } as AxiosRequestHeaders,
            } as AxiosRequestConfig;

            return await axios
                .get('https://api-nz.addysolutions.com/search/?max=5&s=' + query, config)
                .then((response) => {
                    return response.data;
                });
        };

        fetchAddressesRequestPromise()
            .then((response) => {
                setMatchedAddresses(response);
            })
            .catch(console.log);
    };

    const renderMatchedAddresses = () => {
        return matchedAddresses?.addresses?.map((address) => {
            return (
                <ListItem disableGutters sx={{ padding: 0 }} key={address.id}>
                    <ListItemButton onClick={() => selectAddress(address.id)}>
                        <ListItemText primary={address.a} />
                    </ListItemButton>
                </ListItem>
            );
        });
    };

    const [enterManually, setEnterManually] = useState(false);

    const enterAddressManaully = () => {
        setEnterManually(true);
    };

    const fetchSelectedAddress = async (id: number) => {
        const fetchAddressRequestPromise = async (): Promise<MatchResponse> => {
            const config = {
                headers: {
                    'addy-api-key': addyKey,
                } as AxiosRequestHeaders,
            } as AxiosRequestConfig;

            return await axios.get('https://api-nz.addysolutions.com/address/' + id, config).then((response) => {
                return response.data;
            });
        };

        fetchAddressRequestPromise()
            .then((response) => {
                setValue('addressLine1', response.address1!);
                setValue('addressLine2', response.address2!);
                setValue('fullAddress', response.full!);
                setValue('suburb', response.suburb!);
                setValue('cityTown', response.city!);
                setValue('postcode', response.postcode!);

                clearErrors('addressLine1');
            })
            .catch(console.log);
    };

    const selectAddress = (id: number) => {
        fetchSelectedAddress(id);
        setMatchedAddresses({} as MatchedAddresses);
    };

    return (
        <>
            <FormControl fullWidth style={{ display: enterManually ? 'none' : 'inherit' }}>
                <FormLabel htmlFor='fullAddress'>Address *</FormLabel>
                <div style={{ position: 'relative' }}>
                    <Controller
                        name='fullAddress'
                        control={control}
                        defaultValue={buildFullAddress(address)}
                        render={({ field: { onChange, ...rest } }) => (
                            <TextField
                                {...rest}
                                id='fullAddress'
                                size={inputSize}
                                placeholder='Start typing...'
                                fullWidth
                                autoComplete='no'
                                onChange={(e) => {
                                    onChange(e);
                                    searchAddresses(e);
                                }}
                                error={errors.addressLine1 !== undefined}
                                helperText={errors.addressLine1 !== undefined ? 'Address required' : ''}
                            />
                        )}
                    />
                    {matchedAddresses?.addresses && (
                        <Paper sx={{ position: 'absolute', zIndex: 9, width: '100%' }}>
                            <List sx={{ padding: 0, maxHeight: '400px', overflowY: 'auto' }}>
                                {renderMatchedAddresses()}
                                <Divider />
                                <ListItem disableGutters sx={{ padding: 0, lineHeight: '24px' }} key='new-client'>
                                    <ListItemButton onClick={enterAddressManaully}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ bgcolor: '#3659fa' }}>
                                                <Add />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Address not found, enter manually' />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Paper>
                    )}
                </div>
            </FormControl>

            <Typography variant={'h6'} style={{ display: enterManually ? '' : 'none' }}>
                Address*
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }} style={{ display: enterManually ? '' : 'none' }} required>
                <FormLabel htmlFor='addressLine1'>Line 1</FormLabel>
                <Controller
                    name='addressLine1'
                    control={control}
                    defaultValue={address?.addressLine1}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='addressLine1'
                            data-testid='addressLine1'
                            autoComplete='no'
                            size={inputSize}
                            error={errors.addressLine1 !== undefined}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel htmlFor='addressLine2'>Line 2</FormLabel>
                <Controller
                    name='addressLine2'
                    control={control}
                    defaultValue={address?.addressLine2}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='addressLine2'
                            autoComplete='no'
                            size={inputSize}
                            error={errors.addressLine2 !== undefined}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} style={{ display: enterManually ? '' : 'none' }}>
                <FormLabel htmlFor='suburb'>Suburb</FormLabel>
                <Controller
                    name='suburb'
                    control={control}
                    defaultValue={address?.suburb}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='suburb'
                            autoComplete='no'
                            size={inputSize}
                            error={errors.suburb !== undefined}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} style={{ display: enterManually ? '' : 'none' }} required>
                <FormLabel htmlFor='cityTown'>City / Town </FormLabel>
                <Controller
                    name='cityTown'
                    control={control}
                    defaultValue={address?.city}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='cityTown'
                            autoComplete='no'
                            size={inputSize}
                            error={errors.cityTown !== undefined}
                        />
                    )}
                />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }} style={{ display: enterManually ? '' : 'none' }} required>
                <FormLabel htmlFor='postcode'>Postcode</FormLabel>
                <Controller
                    name='postcode'
                    control={control}
                    defaultValue={address?.postCode}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id='postcode'
                            autoComplete='no'
                            size={inputSize}
                            error={errors.postcode !== undefined}
                        />
                    )}
                />
            </FormControl>

            <FormControl component='fieldset'>
                <Controller
                    rules={{ required: true }}
                    control={control}
                    name='addressType'
                    render={({ field }) => (
                        <RadioGroup {...field} row name='addressType' id='addressType'>
                            <FormControlLabel value={AddressType.PHYSICAL} control={<Radio />} label='Physical' />
                            <FormControlLabel value={AddressType.POSTAL} control={<Radio />} label='Postal' />
                        </RadioGroup>
                    )}
                />
            </FormControl>
        </>
    );
};

export default AddressFields;

type MatchResponse = {
    address1?: string;
    address2?: string;
    full?: string;
    suburb?: string;
    city?: string;
    postcode?: string;
};
