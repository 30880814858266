import { yupResolver } from '@hookform/resolvers/yup';
import {
    Alert,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
    Client,
    ContactMethodType,
    IndividualClient,
    updateIndividual,
    UpdateIndividualFields,
} from '../../../apis/clients';
import { LoadingButton } from '../../../components/LoadingButton';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange, invalidDate } from '../../../util/dateUtils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const schema = yup.object({
    firstName: yup.string().required('First name required'),
    lastName: yup.string().required('Last name required'),
    dateOfBirth: yup
        .date()
        .nullable()
        .transform((curr) => (invalidDate(curr) ? null : curr))
        .min(moment().subtract(120, 'years'), 'Invalid birth year')
        .max(moment().subtract(18, 'years'), 'Must be at least 18 years old'),
    preferredContactMethodType: yup
        .mixed<ContactMethodType>()
        .oneOf(Object.values(ContactMethodType))
        .required('Preferred contact method required'),
});

interface UpdateIndividualClientProps {
    callback: (client: Client) => void;
    closeDialog?: () => void;
    client: IndividualClient;
}

const UpdateIndividualClient = ({ callback, client, closeDialog }: UpdateIndividualClientProps) => {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<UpdateIndividualFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<UpdateIndividualFields> = (data) => {
        setSubmitting(true);

        const validData = {
            ...data,
            dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).format(DATE_SERVER_FORMAT) : undefined,
        };

        updateIndividual(validData, client?.uuid)
            .then((clientResponse) => {
                callback(clientResponse);
            })
            .catch(({ response }) => {
                setErrorMsg(response.data.message);
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
            }}
        >
            <DialogTitle>Edit client</DialogTitle>

            <DialogContent sx={{ width: 480 }}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='firstName'>First name</FormLabel>
                            <Controller
                                name='firstName'
                                control={control}
                                defaultValue={client?.personalDetails.givenName}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size='medium'
                                        id='firstName'
                                        autoComplete='no'
                                        error={!!errors?.firstName}
                                        helperText={errors.firstName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='lastName'>Last name</FormLabel>
                            <Controller
                                name='lastName'
                                control={control}
                                defaultValue={client?.personalDetails.surname}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        size='medium'
                                        id='lastName'
                                        autoComplete='no'
                                        error={!!errors?.lastName}
                                        helperText={errors.lastName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='dateOfBirth'>Date of birth</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Controller
                                    name='dateOfBirth'
                                    control={control}
                                    defaultValue={
                                        client?.personalDetails.dateOfBirth == null
                                            ? ''
                                            : moment(client?.personalDetails.dateOfBirth).format(DATE_SERVER_FORMAT)
                                    }
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: 'dateOfBirth',
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    helperText: errors?.dateOfBirth?.message,
                                                    error: !!errors?.dateOfBirth,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='preferredContactMethodType'>Preferred contact method</FormLabel>
                            <Controller
                                name='preferredContactMethodType'
                                control={control}
                                defaultValue={client?.preferredContactMethodType}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        id='entityType'
                                        fullWidth
                                        error={!!errors?.preferredContactMethodType}
                                        size='medium'
                                    >
                                        <MenuItem value={ContactMethodType.EMAIL}>Email</MenuItem>
                                        <MenuItem value={ContactMethodType.POST}>Post</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Grid>

                    {errorMsg && (
                        <Grid item sm={12}>
                            <Alert severity='error'>{errorMsg}</Alert>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button size='large' onClick={closeDialog}>
                    Close
                </Button>
                <LoadingButton
                    data-testid='saveClient'
                    type='submit'
                    variant='contained'
                    size='large'
                    loading={submitting}
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </form>
    );
};

export default UpdateIndividualClient;
