import { assignDefaultListParams, Direction, ListParams } from './common';
import axios from 'axios';

export enum NotificationStatus {
    PRINT_REQUIRED = 'PRINT_REQUIRED',
    PRINTED = 'PRINTED',
}

export type PrintRequestPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: PrintRequestSummary[];
};

export type PrintRequestSummary = {
    clientName: string;
    clientUuid: string;
    effectiveDate: string;
    fileName: string;
    invoiceUuid: string;
    letterType: string;
    notificationIdentifier: string;
    notificationAttachmentIdentifier: string;
    policyNumber: string;
    policyProduct: string;
    policyUuid: string;
    status: NotificationStatus;
};

export enum PrintRequestSortProperty {
    EFFECTIVE_DATE = 'EFFECTIVE_DATE',
    STATUS = 'STATUS',
}

type ListPrintRequestParams = ListParams & {
    printRequestSortProperty: PrintRequestSortProperty;
    direction: Direction;
    statuses?: NotificationStatus[];
    startDate?: string;
    endDate?: string;
};

export const searchPrintRequest = async (partial?: Partial<ListPrintRequestParams>): Promise<PrintRequestPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/notifications/_query/print-request`, searchRequest)
        .then(({ data }) => data);
};
