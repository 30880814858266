import { Box, InputLabel, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Client, InsuredType } from '../../apis/clients';
import CreateBusinessClient from './CreateBusinessClient';
import CreateIndividualClient from './CreateIndividualClient';
import CreateTrustClient from './CreateTrustClient';

interface CreateClientProps {
    callback?: (client: Client) => void;
}

const CreateClient = ({ callback }: CreateClientProps) => {
    const [clientType, setClientType] = useState<InsuredType>(InsuredType.INDIVIDUAL);

    const handleType = (_event: React.MouseEvent<HTMLElement>, newType: InsuredType | null) => {
        if (newType != null) {
            setClientType(newType);
        }
    };

    const getCreateElement = (clientType: InsuredType): React.ReactElement => {
        switch (clientType) {
            case InsuredType.INDIVIDUAL:
                return <CreateIndividualClient callback={callback} />;
            case InsuredType.BUSINESS:
                return <CreateBusinessClient callback={callback} />;
            case InsuredType.TRUST:
                return <CreateTrustClient callback={callback} />;
        }
    };

    return (
        <>
            <Box p={2}>
                <Typography variant={'h5'}>New client</Typography>
                <Box sx={{ pt: 3 }}>
                    <InputLabel>Client type</InputLabel>
                    <ToggleButtonGroup value={clientType} exclusive onChange={handleType} color='primary' size='medium'>
                        <ToggleButton value={InsuredType.INDIVIDUAL} aria-label={InsuredType.INDIVIDUAL}>
                            Individual
                        </ToggleButton>
                        <ToggleButton value={InsuredType.BUSINESS} aria-label={InsuredType.BUSINESS}>
                            Business
                        </ToggleButton>
                        <ToggleButton value={InsuredType.TRUST} aria-label={InsuredType.TRUST}>
                            Trust
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box p={2}>{getCreateElement(clientType)}</Box>
        </>
    );
};
export default CreateClient;
