import {
    Alert,
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    TextField,
} from '@mui/material';
import { ContactDetails, PhoneNumber } from '../../../types/Types';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { LoadingButton } from '../../../components/LoadingButton';
import { Client, createPhoneNumberPromise, updatePhoneNumberPromise } from '../../../apis/clients';

type Props = {
    phoneNumber?: PhoneNumber;
    client: Client;
    callbackPhoneNumberUpdate: (contactDetails: ContactDetails) => void;
    closeDialog: () => void;
};

const schema = yup.object({
    number: yup
        .string()
        .required('Number is required')
        .matches(/(^(\+?64|0)2[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$)/, 'Valid mobile number required'),
    preferred: yup.boolean().optional(),
});

export default function PhoneNumberForm({
    phoneNumber,
    client,
    callbackPhoneNumberUpdate,
    closeDialog,
}: Readonly<Props>) {
    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<PhoneNumber>({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => {
            return phoneNumber;
        }, [phoneNumber]),
    });

    const onSubmit: SubmitHandler<PhoneNumber> = (data) => {
        setErrorMsg(undefined);
        setSubmitting(true);
        if (phoneNumber?.uuid) {
            updatePhoneNumber(client.uuid, data);
        } else {
            savePhoneNumber(client.uuid, data);
        }
    };

    const updatePhoneNumber = async (clientUuid: string, phoneNumber: PhoneNumber) => {
        updatePhoneNumberPromise(clientUuid, phoneNumber)
            .then((contactDetails: ContactDetails) => {
                callbackPhoneNumberUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    const savePhoneNumber = async (clientUuid: string, phoneNumber: PhoneNumber) => {
        createPhoneNumberPromise(clientUuid, phoneNumber)
            .then((contactDetails: ContactDetails) => {
                callbackPhoneNumberUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
            }}
        >
            <DialogTitle>{phoneNumber ? <>Add new phone number</> : <>Update phone number</>}</DialogTitle>

            <DialogContent sx={{ width: 480 }}>
                <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <FormLabel htmlFor='number'>Number</FormLabel>
                        <Controller
                            name='number'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id='number'
                                    autoComplete='no'
                                    error={!!errors?.number}
                                    helperText={errors?.number?.message}
                                />
                            )}
                        />
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ position: 'relative' }}>
                        <Controller
                            name='preferred'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={onChange}
                                            checked={value}
                                            id='preferred'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                    }
                                    label='Set as preferred phone number'
                                />
                            )}
                        />
                    </Box>
                </Box>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button size='large' onClick={closeDialog}>
                    Close
                </Button>
                <LoadingButton data-testid='save-phone-number' type='submit' variant='contained' loading={submitting}>
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
