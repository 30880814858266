import {
    Box,
    Button,
    Drawer,
    FormControl,
    FormLabel,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import ClientList from '../components/ClientList';
import { AddCircleOutline, Search } from '@mui/icons-material';
import CreateClient from './CreateClient';
import { useAppSelector } from '../store/reducer/Hooks';

const ClientSearch = () => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed } = permissions;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [query, setQuery] = useState<string | undefined>('');
    const searchBar = useRef<HTMLInputElement>(null);

    const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
        const newValue = searchBar.current?.value;
        if (e.key === 'Enter' && newValue !== query) {
            setQuery(newValue);
        }
    };

    return (
        <Box>
            <Grid container>
                <Grid item sm={10}>
                    <Typography variant='h3' component='h1' mb={1}>
                        Clients
                    </Typography>
                    <Typography mb={4}>Find clients by name email or phone number.</Typography>
                </Grid>
                {clientUpdateAllowed && (
                    <Grid item sm={2} style={{ color: '#FFF', textAlign: 'right' }}>
                        <div>
                            <Button variant='contained' startIcon={<AddCircleOutline />} onClick={handleOpen}>
                                New Client
                            </Button>
                        </div>
                    </Grid>
                )}
            </Grid>
            <Paper variant={'flat'} sx={{ py: 3, px: 2 }}>
                <Box sx={{ display: 'flex', gap: 3, pb: 4 }}>
                    <FormControl sx={{ minWidth: 280 }}>
                        <FormLabel htmlFor='client-search-text' sx={{ mb: 1 }}>
                            Search
                        </FormLabel>
                        <TextField
                            id='client-search-text'
                            placeholder='by name, email, or phone #'
                            onKeyDown={handleKeyPress}
                            fullWidth
                            inputRef={searchBar}
                            size='small'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Box>
                <ClientList query={query} />
            </Paper>

            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '572px' },
                }}
            >
                <CreateClient />
            </Drawer>
        </Box>
    );
};

export default ClientSearch;
