import { Alert, Box, Button, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { Client } from '../../../apis/clients';
import { Invoice, InvoiceDetailsResponse, PaymentFrequency, Policy } from '../../../apis/invoice';
import { PolicyVersion, PolicyVersionPreview, PolicyVersionRequest, putQuote, QuoteStatus } from '../../../apis/quotes';
import { LoadingButton } from '../../LoadingButton';
import StepCard from '../../StepCard';
import { ActionType } from '../common';
import ItemCoverPreview from './ItemCoverPreview';
import NewInstalmentDetails from './NewInstalmentDetails';
import PremiumImpact from './PremiumImpact';
import SelectAction from './SelectAction';

type Props = {
    policy: Policy;
    goBack: () => void;
    updateInvoice: (actionType: ActionType, invoiceDetails: InvoiceDetailsResponse) => void;
    policyVersionPreview: PolicyVersionPreview;
    policyVersionRequest: PolicyVersionRequest;
    client: Client;
    invoice: Invoice;
};

const confirmError = 'You must confirm the policy holder is aware of this endorsement.';

export default function ReviewQuoteStep({
    goBack,
    policyVersionPreview,
    policy,
    client,
    updateInvoice,
    policyVersionRequest,
    invoice,
}: Readonly<Props>) {
    const [selectedAction, setSelectedAction] = useState<ActionType>(ActionType.SEND_QUOTE);
    const [errorMsg, setErrorMsg] = useState<string>();
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const alreadyQuoted = policyVersionRequest?.quote?.status === QuoteStatus.QUOTED;
    const showInstalmentPreviews =
        invoice.term.paymentFrequency !== PaymentFrequency.IN_FULL &&
        getInstalmentAmount(policy.currentPolicyVersion, invoice) !==
            getInstalmentAmount(policyVersionPreview.versionPreview, invoice);

    const submitAction = () => {
        if (!confirmed && selectedAction === ActionType.CREATE_ENDORSEMENT) {
            setErrorMsg(confirmError);
            return;
        }

        setLoading(true);
        setErrorMsg(undefined);
        const request = cloneDeep(policyVersionRequest);

        switch (selectedAction) {
            case ActionType.SEND_QUOTE:
                request.quote.status = QuoteStatus.QUOTED;
                break;
            case ActionType.CREATE_ENDORSEMENT:
                request.quote.status = QuoteStatus.ACCEPTED;
                break;
            case ActionType.SAVE_DRAFT:
                request.quote.status = QuoteStatus.DRAFT;
                break;
        }

        putQuote(invoice.uuid, policy.uuid, request)
            .then((invoiceUpdate) => updateInvoice(selectedAction, invoiceUpdate))
            .catch(() => {
                setErrorMsg('Something went wrong, please try again.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCheckConfirmation = (checked: boolean) => {
        setErrorMsg(checked ? undefined : confirmError);
        setConfirmed(checked);
    };

    const handleSelectAction = (actionType: ActionType) => {
        setSelectedAction(actionType);
        if (actionType !== ActionType.CREATE_ENDORSEMENT) {
            setErrorMsg(undefined);
            setConfirmed(false);
        }
    };

    return (
        <StepCard>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                <Typography variant='h5'>Review</Typography>
                <ItemCoverPreview policyVersionPreview={policyVersionPreview} />
                <PremiumImpact
                    policy={policy}
                    policyVersionPreview={policyVersionPreview}
                    paymentFrequency={invoice.term.paymentFrequency}
                />
                {showInstalmentPreviews && (
                    <NewInstalmentDetails invoice={invoice} policyVersionPreview={policyVersionPreview} />
                )}
                <SelectAction
                    selectedAction={selectedAction}
                    handleSelectAction={handleSelectAction}
                    client={client}
                    alreadyQuoted={alreadyQuoted}
                    handleCheckConfirmation={handleCheckConfirmation}
                    confirmed={confirmed}
                />

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                        {!alreadyQuoted && (
                            <Button onClick={goBack} variant='text' color='primary' sx={{ mr: 1 }}>
                                Back
                            </Button>
                        )}
                    </Box>
                    <LoadingButton variant='contained' onClick={submitAction} loading={loading}>
                        {submitMessage(selectedAction, alreadyQuoted)}
                    </LoadingButton>
                </Box>
            </Box>
        </StepCard>
    );
}

const submitMessage = (actionType: ActionType, alreadyQuoted: boolean) => {
    switch (actionType) {
        case ActionType.SEND_QUOTE:
            return alreadyQuoted ? 'Resend quote' : 'Send quote';
        case ActionType.CREATE_ENDORSEMENT:
            return 'Create endorsement';
        case ActionType.SAVE_DRAFT:
            return 'Save as draft';
    }
};

const getInstalmentAmount = (version: PolicyVersion, invoice: Invoice): number => {
    return (
        version.instalmentPreviews
            .map((preview) => preview.instalmentPreview)
            .find((preview) => preview.paymentFrequency === invoice.term.paymentFrequency)?.instalmentAmount ?? 0
    );
};
