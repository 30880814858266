import { Box, Paper, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { CoverChargeType, PolicyVersionPreview } from '../../../apis/quotes';
import { DATE_FRIENDLY } from '../../../util/dateUtils';
import CoverRow from './CoverRow';

type Props = {
    policyVersionPreview: PolicyVersionPreview;
};

export default function ItemCoverPreview({ policyVersionPreview }: Readonly<Props>) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
                <Typography variant='h2' pb={0}>
                    Lives insured
                </Typography>
            </Box>
            {policyVersionPreview.versionPreview.items.map((item) => {
                return (
                    <Paper
                        key={item.name}
                        variant='outlined'
                        sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}
                    >
                        <Box>
                            <Typography variant='h6'>{item.name}</Typography>
                        </Box>
                        {item.coverSets
                            .sort((a, b) => moment(a.coverStartDate).diff(b.coverStartDate))
                            .map((coverSet) => {
                                const total = coverSet.covers
                                    .flatMap((cover) => cover.coverOptions[0].coverCharges)
                                    .filter((charge) =>
                                        [CoverChargeType.GST, CoverChargeType.PREMIUM].includes(charge.type)
                                    )
                                    .reduce((a, v) => a + v.amount, 0);

                                return (
                                    <Paper key={coverSet.coverStartDate} variant='outlined'>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                px: 2,
                                                py: 1,
                                                borderTopLeftRadius: theme.shape.borderRadius + 'px',
                                                borderTopRightRadius: theme.shape.borderRadius + 'px',
                                                backgroundColor: grey[50],
                                                borderBottomColor: grey[300],
                                                borderBottomWidth: '1px',
                                                borderBottomStyle: 'solid',
                                            }}
                                        >
                                            <Box flexGrow={1}>
                                                <Typography variant='subtitle2'>
                                                    Requested coverage from{' '}
                                                    {moment(coverSet.coverStartDate).format(DATE_FRIENDLY)}
                                                </Typography>
                                            </Box>
                                            <Box width={200}>
                                                <Typography variant='subtitle2'>Sum insured</Typography>
                                            </Box>
                                            <Box width={200} textAlign='right'>
                                                <Typography variant='subtitle2'>Annual premium</Typography>
                                            </Box>
                                        </Box>
                                        {coverSet.covers
                                            .filter((cover) => cover.fixedCoverage)
                                            .map((cover) => (
                                                <CoverRow key={cover.productCoverIdentifier} cover={cover} />
                                            ))}
                                        {coverSet.covers
                                            .filter((cover) => cover.coverageAmount > 0 && !cover.fixedCoverage)
                                            .map((cover) => (
                                                <CoverRow key={cover.productCoverIdentifier} cover={cover} />
                                            ))}
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, py: 1 }}>
                                            <Box>
                                                <Typography variant='subtitle2'>Total</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant='subtitle2'>
                                                    {currencyFormatter.format(total)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Paper>
                                );
                            })}
                    </Paper>
                );
            })}
        </Box>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
