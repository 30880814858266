import { Box } from '@mui/material';
import { useState } from 'react';
import { InvoiceDetailsResponse } from '../../apis/invoice';
import SnackAlert, { SnackState } from '../../components/SnackAlert';
import InvoiceActionButton from './Actions';
import InvoiceBanners from './Banners';
import InvoiceBreakdown from './InvoiceBreakdown';
import InvoiceFields from './InvoiceFields';
import PolicyHeader from './PolicyHeader';

export type LoadedInvoicePageProps = {
    approvalRequestSentCount: number;
    setApprovalRequestSentCount: (count: number) => void;
};

type Props = LoadedInvoicePageProps & {
    data: InvoiceDetailsResponse;
};

export default function LoadedInvoicePage({
    approvalRequestSentCount,
    setApprovalRequestSentCount,
    data,
}: Readonly<Props>) {
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackState, setSnackState] = useState<SnackState | undefined>();

    const closeSnackbar = () => {
        setSnackOpen(false);
    };

    const handleSetSnack = (state: SnackState) => {
        setSnackState(state);
        setSnackOpen(true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <PolicyHeader
                    policy={data.invoice.portfolio.policies[0]}
                    sellerProductLogo={data.invoice.productLogoUrl}
                />
                <Box>
                    <InvoiceActionButton
                        setApprovalRequestSentCount={setApprovalRequestSentCount}
                        approvalRequestSentCount={approvalRequestSentCount}
                        invoiceDetails={data}
                        handleSetSnack={handleSetSnack}
                    />
                </Box>
                <SnackAlert state={snackState} open={snackOpen} closeSnackbar={closeSnackbar} />
            </Box>
            <InvoiceFields invoice={data.invoice} />
            <Box mb={4}>
                <InvoiceBanners invoiceDetails={data} handleSetSnack={handleSetSnack} />
            </Box>
            <InvoiceBreakdown
                invoiceDetails={data}
                onApprovalRequestSent={() => setApprovalRequestSentCount(approvalRequestSentCount + 1)}
            />
        </Box>
    );
}
